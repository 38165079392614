<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-combobox
        v-model="formattedDates"
        multiple
        chips
        small-chips
        label="Muistutuspäivät"
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        :rules="validations.requiredArray.concat(validations.arrayMaxLength(5))"
      ></v-combobox>
    </template>
    <v-date-picker
      v-model="contractOptions.reminders.items[idx].dates"
      multiple
      scrollable
      :events="calendarEvents"
      :event-color="getCalendarColors"
      :allowed-dates="allowedReminderDates"
      show-adjacent-months
    >
      <v-btn
        text
        color="error"
        @click="
          menu = false;
          contractOptions.reminders.items[idx].dates = [];
        "
      >
        Peruuta
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="info" @click="menu = false">Hyväksy</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import validations from "@/validations";

export default {
  props: {
    idx: { type: Number, default: null },
    revokedDate: { type: String, default: "" },
  },

  data() {
    return {
      menu: false,
      formattedDates: "",
      validations,
    };
  },

  computed: {
    ...mapState("form", ["contractOptions"]),
    ...mapState("contract", ["currentContract"]),

    calendarEvents() {
      return this.startOfContract && this.selectedRevokedDate
        ? [this.startOfContract, this.selectedRevokedDate]
        : [this.startOfContract];
    },

    startOfContract() {
      return this.currentContract?.lease?.startDate
        ? moment(this.currentContract.lease.startDate).format("YYYY-MM-DD")
        : null;
    },

    selectedRevokedDate() {
      return this.revokedDate ? this.revokedDate : null;
    },
  },

  watch: {
    "contractOptions.reminders.items": {
      deep: true,
      handler() {
        this.formattedDates = this.contractOptions.reminders.items[this.idx].dates.map((el) =>
          moment(el).format("DD.MM.YYYY")
        );
      },
    },
  },

  methods: {
    getCalendarColors(date) {
      if (this.startOfContract) {
        if (moment(date).isSame(this.startOfContract)) return "green";
      }

      if (this.selectedRevokedDate) {
        if (moment(date).isSame(this.selectedRevokedDate)) return "red";
      }
    },

    allowedReminderDates(val) {
      const todayPlus3Days = moment().add(3, "days").format("YYYY-MM-DD");
      if (moment(val).isBefore(todayPlus3Days)) return false;
      return true;
    },
  },
};
</script>

<style></style>
