<template>
  <div>
    <v-form ref="form">
      <v-checkbox
        v-model="contractOptions.sendActivationForm"
        label="Lähetä lomakkeita"
      ></v-checkbox>

      <div v-if="contractOptions.sendActivationForm">
        <v-autocomplete
          v-model="contractOptions.formIds"
          :items="activeForms"
          item-text="name"
          item-value="_id"
          label="Lähetettävät lomakkeet"
          multiple
          small-chips
          deletable-chips
          outlined
          @focus="getActiveForms"
          :search-input.sync="searchInput"
          @change="
            searchInput = '';
            deleteExtraReminders();
          "
          @
          :rules="validations.requiredArray"
        ></v-autocomplete>

        <WarningComponent v-if="currentFormsIncludeNewForms">
          Sopimus sisältää jo valitun lomakkeen. Poista kyseinen lomake sopimuksen riviltä toiminnot
          kohdasta ennen kuin yrität liittää sitä uudelleen.
        </WarningComponent>

        <h4 class="mb-1">Kenelle lomakkeet lähetetään?</h4>

        <v-text-field
          v-model="contractOptions.sendFormTo"
          label="Sähköpostit"
          outlined
          dense
          placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
          :rules="validations.required.concat(validateEmails)"
          hint="Erota sähköpostit pilkulla ja välilyönnillä"
          persistent-hint
        ></v-text-field>

        <h4 class="mb-1 mt-1">Kuka vastaanottaa lomakkeiden viestit?</h4>

        <v-text-field
          v-model="contractOptions.recipients"
          label="Sähköpostit"
          outlined
          dense
          placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
          :rules="validateEmails"
          hint="Erota sähköpostit pilkulla ja välilyönnillä"
          persistent-hint
        ></v-text-field>

        <v-checkbox
          v-model="contractOptions.reminders.active"
          label="Lisää muistutusviestit"
          @change="!contractOptions.reminders.active ? deleteAllReminders() : null"
        ></v-checkbox>

        <div v-if="contractOptions.reminders.active">
          <h4>Muistutukset</h4>

          <p class="mb-2">Voit lisätä maksimissaan 5 muistutuspäivää per lomake.</p>

          <div v-for="(item, idx) in contractOptions.reminders.items" :key="idx">
            <small style="cursor: pointer" class="error--text" @click="deleteReminder(idx)">
              Poista
            </small>

            <v-select
              v-model="item.formId"
              :items="selectedForms"
              outlined
              dense
              label="Lomake"
              item-text="name"
              item-value="_id"
              :rules="validations.required.concat(validateUniqueForm(idx))"
            ></v-select>

            <contract-activation-date-picker
              :idx="idx"
              :revokedDate="revokedDate"
            ></contract-activation-date-picker>

            <v-divider class="mt-1 mb-1"></v-divider>
          </div>

          <v-btn small color="info" @click="addReminder" class="mb-2 mt-1">Lisää muistutus</v-btn>
        </div>

        <h4 class="mb-1">Oma viesti sähköpostipohjan oheen</h4>

        <v-textarea
          v-model="contractOptions.customMessage"
          outlined
          dense
          rows="4"
          placeholder="Rivinvaihdon saat painamalla enteriä..."
        ></v-textarea>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import validations from "@/validations";
import { getRentalContractTenantEmails } from "@/utils/rentalContractHelpers";
import mixins from "@/mixins/mixins";
import ContractActivationDatePicker from "./ContractActivationDatePicker.vue";

export default {
  props: {
    revokedDate: { type: String, default: "" },
  },

  mixins: [mixins],

  components: {
    ContractActivationDatePicker,
  },

  data() {
    return {
      searchInput: "",
      validations,
      getRentalContractTenantEmails,
    };
  },

  computed: {
    ...mapState("form", ["contractOptions", "activeForms"]),
    ...mapState("contract", ["currentContract"]),

    selectedForms() {
      return this.activeForms.filter((el) => this.contractOptions.formIds.includes(el._id));
    },

    validateEmails() {
      return [
        (v) =>
          !v ||
          v.split(", ").every((email) => validations.isValidEmail(email)) ||
          "Emailit on väärin muotoiltu",
      ];
    },

    currentFormIds() {
      if (this.currentContract.forms) {
        return this.currentContract.forms.map((el) => {
          if (el.form) return el.form._id;
        });
      } else {
        return [];
      }
    },

    currentFormsIncludeNewForms() {
      return this.contractOptions.formIds.some((value) => this.currentFormIds.includes(value));
    },
  },

  watch: {
    "contractOptions.sendActivationForm"(val) {
      if (!val) {
        this.resetFields();
      }

      if (val) {
        this.setTenantEmailsAsReceiver();
      }
    },
  },

  methods: {
    ...mapActions("form", ["getActiveForms"]),
    ...mapMutations("form", ["addReminder", "deleteReminder"]),

    addReminder() {
      this.contractOptions.reminders.items.push({ dates: [], formId: null });
    },

    deleteReminder(idx) {
      this.contractOptions.reminders.items.splice(idx, 1);
    },

    deleteExtraReminders() {
      this.contractOptions.reminders.items = this.contractOptions.reminders.items.filter((el) => {
        if (this.contractOptions.formIds.includes(el.formId) || el.formId === null) return true;
        return false;
      });
    },

    deleteAllReminders() {
      this.contractOptions.reminders.items = [];
    },

    validateUniqueForm(currentIdx) {
      return (id) => {
        const isDuplicate = this.contractOptions.reminders.items.some((item, idx) => {
          return idx !== currentIdx && item.formId === id;
        });
        return !isDuplicate || "Samaa lomaketta ei voi valita kahdesti";
      };
    },

    resetFields() {
      this.contractOptions.sendActivationForm = false;
      this.contractOptions.formIds = [];
      this.contractOptions.recipients = "";
      this.contractOptions.sendFormTo = "";
      this.contractOptions.customMessage = "";
      this.contractOptions.reminders.active = false;
      this.contractOptions.reminders.items = [];
    },

    setTenantEmailsAsReceiver() {
      const emails = getRentalContractTenantEmails(this.currentContract);
      this.contractOptions.sendFormTo = emails.join(", ");
    },

    validate() {
      if (this.currentFormsIncludeNewForms) {
        this.showPopup("Tarkista lomakkeen huomiot!", "error");
        return false;
      }

      if (
        this.contractOptions.reminders.active &&
        this.contractOptions.reminders.items.length === 0
      ) {
        this.showPopup(
          "Olet laittanut muistutukset päälle. Lisää ainakin yksi muistutuspäivä.",
          "error"
        );
        return false;
      }

      if (this.$refs.form.validate()) {
        return true;
      } else {
        this.showPopup("Tarkista kentät", "error");
        return false;
      }
    },
  },
};
</script>

<style></style>
