<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>Palauta {{ deposit.name }} ({{ formatCurrency(deposit.amount) }})</v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-5">
          <v-col md="10">
            <v-form ref="form">
              <v-text-field
                v-model.number="deposit.releaseAmount"
                dense
                outlined
                type="number"
                step="0.01"
                :rules="positiveNumber.concat(maxAmount)"
                label="Palautettu summa"
              ></v-text-field>

              <v-menu
                ref="releaseDateMenu"
                v-model="releaseDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedReleaseDate"
                    dense
                    outlined
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Palautuspäivä"
                    v-prevent-manual-input
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deposit.releaseDate"
                  first-day-of-week="1"
                  @input="releaseDateMenu = false"
                ></v-date-picker>
              </v-menu>

              <v-textarea v-model="deposit.comment" label="Kommentti" dense outlined></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="releaseDeposit()"> Tallenna </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  props: {
    value: { type: Boolean, default: false },

    contractNum: {
      type: Number,
      default: 0,
    },

    contractId: {
      type: String,
      default: "",
    },

    depositObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  mixins: [mixins],

  data(vm) {
    return {
      releaseDateMenu: false,
      dateFormattedReleaseDate: "",
      deposit: {
        depositType: "",
        name: "",
        payDate: "",
        releaseDate: "",
        releaseAmount: null,
        amount: null,
        comment: "",
        _id: "",
      },
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.testNumber(v) || "Summan pitää olla 0 tai suurempi"],
      maxAmount: [(v) => vm.testMaxAmount(v) || `Maksimisumma on ${vm.deposit.amount} €`],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "deposit.releaseDate"() {
      this.dateFormattedReleaseDate = this.formatDate(this.deposit.releaseDate);
    },

    depositObj: function (obj) {
      this.deposit = obj || {};

      if (this.deposit.releaseDate) {
        this.deposit.releaseDate = new Date(this.deposit.releaseDate)
          .toISOString()
          .substring(0, 10);
      } else {
        this.deposit.releaseDate = new Date(new Date()).toISOString().substring(0, 10);
      }
    },
  },

  methods: {
    async releaseDeposit() {
      if (this.$refs.form.validate()) {
        try {
          const res = await axiosMethods.update(
            "/api/v1/rental-contract/releaseDeposit/" + this.contractId,
            this.deposit
          );

          this.$emit("updateddeposits", res.data.contract.deposits);

          this.showPopup("Vakuus muokattu", "success");
          this.dialog = false;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    testMaxAmount(val) {
      return val > this.deposit.amount ? false : true;
    },

    testNumber(val) {
      return val >= 0 ? true : false;
    },
  },
};
</script>

<style scoped></style>
