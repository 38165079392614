// Get tenant emails

export const getRentalContractTenantEmails = (contract) => {
  let emails = [];
  // Tenant
  if (contract?.tenant?.tenantId?.email) {
    emails.push(contract.tenant.tenantId.email);
  }
  // Other tenants
  contract.otherTenants.forEach((el) => {
    if (el.tenantId?.email) emails.push(el.tenantId.email);
  });
  return emails;
};
