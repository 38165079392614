<template>
  <v-dialog v-model="dialog" width="650">
    <v-card>
      <v-card-title v-if="!refreshRevoked">
        Sopimuksen nro. {{ selectedItem.contractNumber }} irtisanominen
      </v-card-title>
      <v-card-title v-else>
        Muokkaa irtisanottua sopimusta {{ selectedItem.contractNumber }}
      </v-card-title>

      <v-card-text v-if="dismissalDateFound || (!refreshRevoked && selectedItem.baseInvoice)">
        <WarningComponent v-if="dismissalDateFound" style="margin-bottom: 0px" color="error">
          Huomioi, että sopimuksen ensimmäinen mahdollinen irtisanomispäivä on
          <strong>{{ formatDate(selectedItem.lease.dismissalDate) }}</strong>
        </WarningComponent>

        <WarningComponent
          v-if="!refreshRevoked && selectedItem.baseInvoice"
          style="margin-bottom: 0px"
          :class="dismissalDateFound ? 'mt-2' : ''"
        >
          Viimeinen toistuva lasku on lasku, jonka eräpäivä kohdistuu irtisanomiskuukaudelle tai sen
          jälkeisille kuukausille.
        </WarningComponent>
      </v-card-text>

      <v-card-text>
        <v-form ref="form">
          <v-radio-group
            v-if="refreshRevoked"
            v-model="refreshTypeNew"
            :mandatory="true"
            style="margin-top: 0px"
          >
            <v-radio label="Sopimus palautetaan voimassa olevaksi" value="cancelRevoked"></v-radio>
            <v-radio
              v-if="selectedItem.state != 'Päättynyt'"
              label="Sopimuksen irtisanomispäivää siirretään"
              value="newRevokedDate"
            ></v-radio>
          </v-radio-group>

          <div v-if="refreshTypeNew == 'newRevokedDate'">
            <h4>Aseta sopimuksen viimeinen voimassaolopäivä (viimeinen vuokranmaksupäivä).</h4>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <v-text-field
                  class="mt-1"
                  :value="formatDate(date)"
                  dense
                  outlined
                  label="Irtisanomispäivä"
                  v-bind="attrs"
                  :rules="validations.required"
                  v-on="on"
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                first-day-of-week="1"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <small
            v-if="refreshRevoked && selectedItem.baseInvoice"
            class="error--text"
            style="font-size: 13px"
          >
            Toistuvaislasku {{ selectedItem.baseInvoice.invoiceNumber }} siirtyy toimenpidelistalle.
            Muokkaa laskua kohdasta laskut -> Toimenpidettä vaativat laskut.
          </small>
        </v-form>
      </v-card-text>

      <!-- SEND FORM WHEN REVOKE -->
      <v-card-text v-show="date && !refreshRevoked">
        <h3>
          Haluatko lähettää vuokralaisille lomakkeita täydennettäviksi irtisanomisen yhteydessä?
        </h3>

        <contract-activation-form
          ref="contractActivationForm"
          :revokedDate="date"
        ></contract-activation-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!refreshRevoked" color="info" @click="revokeContract()"
          >Irtisano sopimus
        </v-btn>
        <v-btn v-else color="info" @click="editRevokedContract()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ContractActivationForm from "./ContractActivationForm.vue";
import mixins from "@/mixins/mixins";
import axiosMethods from "../../mixins/axios";
import validations from "@/validations";
import { mapState } from "vuex";

export default {
  mixins: [mixins],

  components: {
    ContractActivationForm,
  },

  props: {
    value: { type: Boolean, default: false },
    selectedItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    refreshRevoked: { type: Boolean, default: false },
    refreshType: { type: String, default: "newRevokedDate" },
  },

  data() {
    return {
      menu: false,
      date: null,
      refreshTypeNew: "newRevokedDate",
      validations,
    };
  },

  computed: {
    ...mapState("form", ["contractOptions"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.contractActivationForm.resetFields();
        this.$emit("input", value);
      },
    },

    dismissalDateFound() {
      return this.selectedItem?.lease?.dismissalDate ? true : false;
    },
  },

  watch: {
    refreshType(val) {
      this.refreshTypeNew = val;
    },

    value(val) {
      if (val) {
        this.$nextTick(() => {
          this.resetFields();
          this.$refs.contractActivationForm.resetFields();
        });
      }
    },
  },

  methods: {
    resetFields() {
      this.date = null;
      this.$refs.form.reset();
    },

    async revokeContract() {
      if (this.$refs.form.validate() && this.$refs.contractActivationForm.validate()) {
        if (confirm("Tarkistithan, että irtisanomispäivämäärä on oikein?")) {
          try {
            await axiosMethods.update(
              "/api/v1/rental-contract/revoke-contract/" + this.selectedItem._id,
              { revokedDate: this.date, form: this.contractOptions }
            );

            this.dialog = false;
            this.showPopup("Sopimus irtisanottu", "success");

            setTimeout(() => {
              this.$router.go();
            }, 1000);
          } catch (err) {
            this.showPopup(err, "error");
          }
        }
      }
    },

    async editRevokedContract() {
      if (this.$refs.form.validate()) {
        if (this.refreshTypeNew == "newRevokedDate") {
          if (!confirm("Tarkistithan, että irtisanomispäivämäärä on oikein?")) {
            return;
          }
        }

        try {
          await axiosMethods.update(
            "/api/v1/rental-contract/refresh-revoked-contract/" + this.selectedItem._id,
            {
              revokedDate: this.refreshTypeNew == "newRevokedDate" ? this.date : null,
              refreshType: this.refreshTypeNew,
            }
          );

          this.dialog = false;
          this.showPopup("Sopimuksen muokkaus onnistui", "success");

          setTimeout(() => {
            this.$router.go();
          }, 1000);
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style></style>
